<template>
  <b-container>
    <div v-if="$route.query.isPass">
      <div class="free-title">
        <span>自由模式</span>
      </div>
      <div class="text-left-tishi">
        <span class="iconfont icon-tishi"></span>
        你当前正在自由模式下训练，可选择任何模块进行学习！
      </div>
    </div>
    <div
      v-else
      v-show="permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1"
    >
      <GlobalButtonParallelogram
        :sprintId="7"
        :buttonColor="`mock-color`"
      ></GlobalButtonParallelogram>
    </div>
    <b-row>
      <b-col
        class="test-card"
        lg="6"
        cols="12"
        v-for="(item,index) in beginData"
        :key="item.examId"
      >
        <b-card>
          <b-row>
            <b-col class="text-left test-title">{{ item.examName }}</b-col>
            <b-col class="text-right" cols="5">
              <div v-if="$route.query.isPass">
                <b-button
                  @click="checkPathFree(item)"
                  class="card-button viewResults"
                  size="lg"
                  v-if="item.state === 2"
                  >重做</b-button
                >
                <span
                  class="free-result"
                  @click="checkPath(item)"
                  v-if="item.state === 2"
                  >查看结果</span
                >
              </div>
              <div v-else>
                <b-button
                  @click="checkPath(item)"
                  class="card-button viewResults"
                  size="lg"
                  v-if="item.state === 2"
                  >查看结果</b-button
                >
              </div>

              <b-button
                @click="checkPath(item)"
                class="card-button carryOn"
                size="lg"
                v-if="item.state === 1"
              >
                <span>继续</span>
                <span class="iconfont iconbianji"></span>
              </b-button>
              <b-button
                @click="checkPath(item)"
                class="card-button AnswerButton"
                size="lg"
                variant="warning"
                v-if="item.state === 0"
                :disabled="![0,1,2,3,4,5].includes(index)"
              >
                <span>答题</span>
                <span class="iconfont iconbianji"></span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="test-time">
            <b-col class="text-left" md="6" sm="12">
              <span class="iconfont iconshijian1"></span>
              <span>预期时间(分钟):</span>
              <span class="text-blod">{{ item.expectedMinutes }}</span>
            </b-col>
            <b-col class="text-left">
              <span class="iconfont iconshijian"></span>
              <span>实际用时(分钟):</span>
              <span class="text-blod">{{ item.answerMinutes }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="simulationScore" md="4" sm="12">
              <b-row>
                <b-col md="12" cols="6">
                  <div class="selectScore">选择题部分(90)</div>
                </b-col>
                <b-col>
                  <div class="interstellarScore">
                    <b-form-rating
                      id="rating-readonly"
                      :value="item.choiceStar"
                      readonly
                      precision="1"
                      stars="3"
                      inline
                      no-border
                      size="lg"
                    ></b-form-rating>
                    <span class="text-blod">{{ item.choiceScores }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4" sm="12">
              <b-row>
                <b-col md="12" cols="6">
                  <div>非选择题部分(60)</div>
                </b-col>
                <b-col>
                  <div class="interstellarScore">
                    <b-form-rating
                      id="rating-readonly"
                      :value="item.nonChoiceStar"
                      readonly
                      precision="1"
                      stars="3"
                      inline
                      no-border
                      size="lg"
                    ></b-form-rating>
                    <span class="text-blod">{{ item.noChoiceScores }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4" sm="12">
              <b-row class="simulatedScore">
                <b-col md="12" cols="6">
                  <div>模拟得分</div>
                </b-col>
                <b-col>
                  <div class="text-blod">
                    {{ item.answerScores }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import GlobalButtonParallelogram from "@/components/global-button/global-button-parallelogram.vue";
import {
  getSimulation,
  getSimulationFreeTraining,
} from "@/api/english-mock.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      beginData: "",
    };
  },
  components: {
    GlobalButtonParallelogram,
  },
  computed: {
    ...mapState(["permission"]),
  },
  mounted() {
    this.getBeginData();
  },
  methods: {
    getBeginData() {
      const parmeter = {};
      if (this.$route.query.isPass) {
        getSimulationFreeTraining(parmeter).then((res) => {
          console.log("自由训练数据", res);
          this.beginData = res.data.result.simulationExams;
        });
      } else {
        getSimulation(parmeter).then((res) => {
          console.log("获取英语模拟首页数据", res);
          this.beginData = res.data.result.simulationExams;
        });
      }
    },
    ...mapMutations(["setMockTestName"]),
    checkPathFree(item) {
      if (item.state == 2) {
        this.$router.push({
          name: "MockPrompt",
          query: {
            state: 0,
            id: item.examId,
            isPass: this.$route.query.isPass,
          },
        });
      }
    },
    checkPath(item) {
      debugger;
      this.setMockTestName(item.examName);
      // 0 未答题  1 已做完听力  2全部完成查看答案
      console.log("item", item);
      if (item.state == 0) {
        this.$router.push({
          name: "MockPrompt",
          query: {
            state: 0,
            id: item.examId,
            isPass: this.$route.query.isPass,
          },
        });
      }
      if (item.state == 1) {
        this.$router.push({
          name: "MockPrompt",
          query: {
            state: 1,
            id: item.examId,
            isPass: this.$route.query.isPass,
          },
        });
      }
      if (item.state == 2) {
        this.$router.push({
          name: "MockReport",
          query: {
            // module: 7,
            state: 1,
            studentExamId: item.studentExamId,
            sprintId: 7,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.test-title {
  font-size: 1.8rem;
  font-weight: bold;
}
.text-left-tishi {
  text-align: left;
  color: #fff;
  font-weight: bold;
  padding-bottom: 1rem;
}
.test-card {
  .card {
    box-shadow: 0px 8px 7px 3px rgba(240, 240, 240, 0.57);
    border-radius: 10px;
    letter-spacing: 1px;
    border: none;
    .form-control {
      color: #fbc65d;
    }
  }
  .iconshijian1,
  .iconshijian {
    color: #f7a234;
    padding-right: 0.5rem;
  }
  .text-blod {
    font-weight: bold;
    padding-left: 0.5rem;
  }
  padding-bottom: 1rem;
}
.test-time {
  border-bottom: 1px solid #e5e5e5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.card-button {
  padding: 0.5rem 2rem;
  color: #fff !important;
  border: none;
  font-size: 1.1428rem;
  cursor: pointer;
}
.carryOn {
  background-color: #34aef3 !important;
}
.AnswerButton {
  background-color: #f7a234 !important;
}
.viewResults {
  background-color: #2cb774 !important;
}
.iconbianji {
  padding-left: 0.5rem;
}
.interstellarScore {
  display: flex;
  align-items: center;
  justify-content: end;
}
.simulatedScore {
  height: 100%;
}
.simulationScore {
  padding-left: 0;
}
.selectScore {
  text-align: left;
  text-indent: 17px;
}
.free-result {
  padding-left: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
.free-title {
  width: 172px;
  height: 49px;
  background: #ffc13b;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  font-size: 30px;
  color: white;
  font-weight: bold;
  transform: skewX(-30deg);
  position: relative;
  display: flex;
  top: -13px;
  left: 13px;
  span {
    width: 100%;
    position: absolute;
    transform: skewX(30deg);
  }
}
</style>
